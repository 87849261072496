.contact-banner {
    background-image: url(../../img/contacts.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    background-color: #005AA6;
}

.contact-banner h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 60px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 0 20px 0;
}

.from-main {
    background: #DCE8F6;
}

.from-section h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    color: #2B2B2B;

}

.iframe {
    width: 100;
    height: 100%;
}

.map-left {
    text-align: left;
}

.map-left h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.map-left h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 50px;
    text-transform: uppercase;
    color: #2B2B2B;
}


.contact-from {}

.contact-from input {
    padding: 7px 0px 7px 15px;
    border: 1px solid rgba(32, 29, 29, 0.5);
    border-radius: 2px;
}

.contact-from ::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;

    color: #949191;

}

.textarea-box {
    padding: 26px 23px 0px 55px;
}

.textarea {
    padding: 10px;
    border: 1px solid rgba(32, 29, 29, 0.5);
}

.form-check {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #403F3F;
    margin: 35px 0 0 0;
}

.form-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #00A652;
    padding: 20px 38px;
    margin: 34px 0;
    border: 1px solid #00A652;
}

.form-button:hover {
    background-color: #00a65300;
    color: #403F3F;
    transition: 0.3s ease-in
}

@media only screen and (max-width: 667px) {

    .contact-banner h1 {
        font-size: 25px;
        margin: 0 0 0px 0;
    }

    .from-main {
        padding: 10px;
    }

    .from-main h1 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 46px;
        text-transform: uppercase;
        color: #2B2B2B;

    }

    .map-left {}

    .map-left h2 {
        font-size: 25px;
        line-height: 40px;
    }

    .map-left h4 {
        font-size: 19px;
        line-height: 40px;
    }

    .contact-from {}

    .contact-from input {
        margin-bottom: 30px;
    }

    .contact-from ::placeholder {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;

        color: #949191;

    }

    .form-group {
        padding: 0 20px;
    }

    .textarea-box {
        padding: 0;

    }

    .textarea {
        padding: 10px;
    }

    .form-check {
        line-height: 25px;
    }

    .form-button {
        padding: 12px 20px;
        margin: 20px 0;
    }

}

@media only screen and (max-width:991px) and (min-width:668px) {}

@media only screen and (max-width: 1200px) and (min-width:992px) {}