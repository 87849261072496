.event {}

.event img {}

.event h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: #2B2B2B;
    margin: 18px 0 15px 0px;
}

.event h5 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #403F3F;

}

.event p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #403F3F;
}


@media only screen and (max-width: 667px) {
    .event h3 {
        font-size: 22px;
        margin: 14px 0 12px 0px;
    }
    
    .event h5 {
        font-size: 17px;
    
    }

}
@media only screen and (max-width:991px) and (min-width:668px){}
@media only screen and (max-width: 1200px) and (min-width:992px) {}