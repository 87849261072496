.menu ul {
    margin: 0;
    padding: 0px 10px;
    border-radius: 5px;
}

.menu ul li {
    list-style-type: none;
    background: #194079;
    color: white;
    line-height: 35px;
    font-size: 17px;
    text-align: left;
    transition: .5s;
}

.menu a {
    color: white;
    text-decoration: none;
    display: block;
}

.menu ul li ul {
    display: none;
    border-radius: 5px;
}

.menu ul li:hover>ul {
    display: block;
}

.menu ul li:hover {}

.menu ul li {
    position: relative;
    border-radius: 5px;
}

.menu ul li ul {
    position: absolute;
    top: 0;
    left: 135px;
    width: 350px;
}

.menu ul li ul li {
    margin: 0px 0 -20px 0px;
    padding: 10px;
    font-size: 13px;
}


.logo {
    width: 90px;
    height: 50px;
    display: none;
}

.nav-main {
    background-color: #00A652;
}

.route-item {
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin: 0px 0px 0px 0px;
    padding: 0 15px;
    color: white;
    text-shadow: 0;

}

.route-item:hover {
    text-decoration: none;
}

.myBorder {

    border-left: 1px solid white;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: blue;

}


.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #194079;
    color: #fbfbfb;
}

.dropdown-divider {
    border-top: 1px solid rgb(229 194 194);
}

.drop-item-main:hover {
    background-color: #194079;

}

.drop-item {
    text-decoration: none;
    color: white;
    padding: 0 0px 0px 16px;
}

.drop-item:hover {
    color: black;
    text-decoration: none;
}

.myul {
    position: absolute;
    background: white;
    top: -45px;
    left: 159px;
    padding: 15px;
    list-style: none;
    border-radius: 5px;
}



.dropdown-menu[data-bs-popper] {
    top: 100%;
    background-color: #194079;
    right: 0px;
    margin-top: 0.1rem;
    border-left: 0;
}

/* =========================== */
.nav-after::after {
    content: "|";
    width: 2px;
    height: 25px;


}

.product-link {
    text-decoration: none;
    list-style: none;
    color: white;
    padding: 0px 7px;
    font-size: 13px;
    margin: 0px;
    display: block;
    text-align: left;
    line-height: 15px;
    margin: 10px 0;
    padding: 7px;
}

.product-link:hover {
    text-decoration: none;
    color: black;
    background-color: white;
}


/* ======================= Media query start ===========================  */

@media only screen and (max-width: 667px) {

    /* ======= multi dropdown start =======  */
    .menu ul {
        margin: 0;
        padding: 0px 10px;
        border-radius: 5px;
    }

    .menu ul li {
        list-style-type: none;
        background: #194079;
        color: white;
        line-height: 35px;
        font-size: 17px;
        text-align: le;
        transition: .5s;
    }

    .menu a {
        color: white;
        text-decoration: none;
        display: block;
    }

    .menu ul li ul {
        display: none;
        border-radius: 5px;
    }

    .menu ul li:hover>ul {
        display: block;
    }

    .menu ul li:hover {}

    .menu ul li {
        position: relative;
        border-radius: 5px;


    }

    .menu ul li ul {
        position: absolute;
        top: 40px;
        left: 0px;
        width: auto;
        z-index: 1;
    }

    .menu ul li ul li {
        margin: 0px 0 -10px 0px;
        font-size: 13px;

    }

    /*  end =================== */

    .logo {
        width: 70px;
        height: 36px;
        display: block;
    }

    .drop-item {
        text-align: center;
    }

    .navbar-nav .show>.nav-link {
        text-align: center;
    }

    .myBorder {

        border-left: 0px solid white;
    }

}

@media only screen and (max-width:991px) and (min-width:668px) {

    .menu ul li {
        list-style-type: none;
        background: #194079;
        color: white;
        line-height: 35px;
        font-size: 17px;
        text-align: le;
        transition: .5s;
    }


    .menu ul li ul {
        position: absolute;
        top: 40px;
        left: 150px;
        z-index: 1;


    }

    .menu ul li ul li {
        font-size: 15px;
        margin: 0px 0 -10px 0px;
        font-size: 13px;

    }

    /* ============end */
    .logo {
        width: 70px;
        height: 36px;
        display: block;
    }

    .route-item-main {
        text-align: center;

    }

    .route-item {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        margin: 0px 10px;

    }

    .myBorder {

        border-left: 0px solid white;
    }


}

@media only screen and (max-width: 1200px) and (min-width:992px) {
    .route-item {
        padding: 0 2px;

    }

}