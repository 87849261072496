.about-banner {
    background-image: url(../../img/aboutbanner.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-banner h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 60px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 0 20px 0;
}

.about-banner p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.about-imag1 {
    width: 100%;
    height: auto;
    object-fit: cover;

}

/* .text-left {
    margin: 0 0 px 40;
}
 */
.wtc-right,
.policy-right h5 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.text-left h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 55px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #2B2B2B;

}

.text-left p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #403F3F;
}

.read-more {
    color: #049c50 !important;

}

.read-more:hover {
    color: #014b26;

}

.strength {
    background: #DCE8F7;
}

.strength h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    color: #2B2B2B;
    margin: 0 0 60px 0;

}

.strength-item {}

.strength-item img {}

.strength-item h4 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #2B2B2B;
    margin: 15px 0 0px 0px;

}

.ethics {}

.ethics h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.ethics-all {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.ethics-items {
    background: #E5F6EE;
    padding: 36px;
    border-radius: 5px;
}

.ethics h5 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    text-align: left;

}

.ethics p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;
    color: #000000;
    text-align: left;
    margin: 12px 0 0 0;

}

.vision-items {
    background: #00A652;
    padding: 36px;
    border-radius: 5px;
}

.vision-items img {}

.vision-items h5 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.vision-items p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
}


@media only screen and (max-width: 667px) {
    .about-banner {
        padding: 0px 10px 0px 10px;
    }

    .about-banner h1 {
        font-weight: 600;
        font-size: 25px;
        line-height: 40px;
        margin: 0 0 10px 0;

    }

    .about-imag1 {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    .text-left {
        margin: 25px 0 0px 0px;
    }


    .text-left h2 {
        font-size: 25px;
        line-height: 35px;

    }

    .text-left p {
        text-align: justify;
    }

    .strength h2 {
        font-size: 25px;
        line-height: 35px;

    }

    .strength-item h4 {
        font-size: 17px;

    }

    .ethics {}

    .ethics-all {
        grid-template-columns: repeat(1, 1fr);
    }

    .ethics h2 {
        font-size: 25px;
        line-height: 35px;
    }


    .ethics-items {
        background: #E5F6EE;
        padding: 25px;
        border-radius: 5px;
    }

    .ethics h5 {
        font-size: 22px;
        line-height: 28px;

    }

    .ethics p {
        font-size: 17px;

    }

    .vision-items {
        padding: 25px;
        border-radius: 5px;
    }

    .vision-items img {
        width: auto;
    }

    .vision-items h5 {
        font-weight: 600;
        font-size: 25px;
    }

    .vision-items p {
        text-align: justify;
    }

    .strength-single-item {
        margin: 0 0 70px 0;
    }


}

@media only screen and (max-width:991px) and (min-width:668px) {}

@media only screen and (max-width: 1200px) and (min-width:992px) {}