.detail-contact {
    background: rgba(215, 237, 255, 0.5);
    border-radius: 5px;
}

.details-items {
    display: flex;
    justify-content: space-between;
}

.detail-contact h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    margin: 10px 0 35px 0px;

}

.detail-contact img {
    color: #005AA6
}

.detail-fast {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: left;
    margin: 0px 0 5px 0;

}

.details-last {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #005AA6;
    text-align: left;

}

@media only screen and (max-width: 667px) {

    .details-items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px 20px;
    }

    .detail-contact h3 {
        font-weight: 600;
        font-size: 25px;
        margin: 10px 0 35px 0px;

    }

    .detail-fast {
        text-align: left;
        margin: 0px 0 5px 0;

    }

    .details-last {
        font-size: 16px;
        text-align: left;

    }

}

@media only screen and (max-width:991px) and (min-width:668px) {
    .details-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 20px;
        margin: auto;
    }

    .details-head {
        font-size: 35px;
        line-height: 32px;
        margin: -7px 0 -7px 0px;
    }
}